$(document).on("turbolinks:load", function() {
  var selectedCategory = window.location.hash.substr(1)

  if (selectedCategory != "") {
    highlightNavLink(selectedCategory)
    selectCategory(selectedCategory)
  }

  $(window).on("hashchange", function() {
    selectedCategory = window.location.hash.substr(1)
    highlightNavLink(selectedCategory)
    selectCategory(selectedCategory)
  })

  function selectCategory(plan) {
    $(".pricing-plans").each(function(index) {
      if ($(this).data("category") == plan) {
        $(this).show()
      } else {
        $(this).hide()
      }
    })
  }

  function highlightNavLink(plan) {
    $(".plan-categories li a ").each(function(index) {
      if ($(this).attr("href") == "#" + plan) {
        $(this)
          .parent()
          .addClass("active")
      } else {
        $(this)
          .parent()
          .removeClass("active")
      }
    })
  }
})
