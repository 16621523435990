$(document).on("turbolinks:load", function() {
  // only do this on the initial page load.
  $(document).on("click", ".app-email-w .open-inline", function(event) {
    $(event.currentTarget)
      .closest(".app-email-w")
      .find(".ae-item")
      .removeClass("active")
    $(event.currentTarget)
      .find(".ae-item")
      .addClass("active")
  })

  $(".app-email-w .open-inline")
    .first()
    .click()
})
