import consumer from "./consumer"

const updateUnreadCounters = (count) => {
  if (count != null) {
    const messageIndicators = Array.from(document.querySelectorAll(".message-indicator-mobile"))
    messageIndicators.push(document.querySelector(".message-indicator"))

    messageIndicators.forEach((messageIndicator) => {
      if (messageIndicator) {
        messageIndicator.textContent = count
        messageIndicator.hidden = count === 0
      }
    })
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const teamElement = document.getElementById("current-team")
  if (teamElement) {
    const teamId = teamElement.getAttribute("data-team-id")

    window["smsChannel"] = consumer.subscriptions.create(
      { channel: "SmsChannel", current_team_id: teamId },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          if (window.todesktop) {
            new Notification(
              "New SMS from " + data["client"]?.first_name + " " + data["client"]?.last_name + "!",
              {
                body: data["sms"]?.body,
              }
            )
          }

          window["newMessage"] = data["sms"]
          window["newMessageClient"] = data["client"]

          updateUnreadCounters(data["unread_count"])
        },
      }
    )
  }
})
