import consumer from "./consumer"

consumer.subscriptions.create("NotifyRevisionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received({ current_git_revision }) {
    // Called when there's incoming data on the websocket for this channel
    const REVISION_LOCALSTORAGE_KEY_NAME = "current_git_revision"
    const currentGitRevision = current_git_revision
    const browserRevision = localStorage.getItem(REVISION_LOCALSTORAGE_KEY_NAME)

    if (browserRevision) {
      if (currentGitRevision !== browserRevision) {
        localStorage.clear()
        localStorage.setItem(REVISION_LOCALSTORAGE_KEY_NAME, currentGitRevision)

        if (window.todesktop) {
          const updateNotification = new Notification("Update Available", {
            body:
              "An update is available for the Toolbox - Click here to refresh (Unsaved changes will be lost, so save first!)",
          })
          updateNotification.onclick = () => {
            window.location.reload()
          }
        } else {
          if (
            window.confirm(
              "An update is available for the Toolbox. Do you want to refresh the page? (Unsaved changes will be lost, so save first!)"
            )
          ) {
            window.location.reload(true)
          }
        }
      }
    } else {
      localStorage.setItem(REVISION_LOCALSTORAGE_KEY_NAME, currentGitRevision)
    }
  },
})
