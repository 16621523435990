require("intl-tel-input/build/css/intlTelInput.css")

import intlTelInput from 'intl-tel-input'
import Inputmask from "inputmask"

// const IP_INFO = process.env.IP_INFO

function enablePhoneFields($scope) {
  $scope.find('input[type="tel"]').each((index, field) => {
    const input = document.querySelector("#client_phone");
    if (input){
      const iti = intlTelInput(input, {
        onlyCountries: ["us", "ca"],
        initialCountry: "us",
        separateDialCode: true,
        nationalMode: false,
        // In case the audience will be increased
        // geoIpLookup: function(callback) {
        //   $.get(`https://ipinfo.io?token=${IP_INFO}`, function() {}, "jsonp").always(function(resp) {
        //     var countryCode = (resp && resp.country) ? resp.country : "us";
        //     callback(countryCode);
        //   });
        // },%
        utilsScript: "https://intl-tel-input.com/node_modules/intl-tel-input/build/js/utils.js",
      });
      iti.promise.then(() => {
        Inputmask({ "mask": input.placeholder.replace(/[0-9]/g, "9") }).mask(input);
      })
      input.addEventListener("countrychange", function() {
        Inputmask({ "mask": input.placeholder.replace(/[0-9]/g, "9") }).mask(input);
      });
      return false
    }
    return true
  })
}

$(document).on("turbolinks:load", function() {
  enablePhoneFields($('body'))
})

$(document).on("sprinkles:update", function(event) {
  enablePhoneFields($(event.target))
})
