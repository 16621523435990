import $ from "jquery"
import "slick-carousel"

/*

Main javascript functions to init most of the elements

#1. TESTIMONIALS
#2. PROJECTS SLIDER

*/

$(document).on("turbolinks:load", function() {
  // #1. TESTIMONIALS
  $(".testimonials-slider").slick({
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  })

  // #2. PROJECTS SLIDER
  $(".projects-slider-i").slick({
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  })
})
